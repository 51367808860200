@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  font-family: "helvetica neue", helvetica, sans-serif;
}
@supports not (color: oklch(0 0 0)) {
  :root {
    color-scheme: light;
    --fallback-p: #28818f;
    --fallback-s: #ac5656;
    --fallback-a: #ffe17d;
    --fallback-n: #fdfdfd;
    --fallback-b1: #f0f0f0;
    --fallback-b2: #b9beb4;
    --fallback-b3: #212427;
    --fallback-in: #a0e2e1;
    --fallback-pf: #7ad8c5;
    --fallback-sf: #ff501a;
    --fallback-af: #ff33ad;
    --fallback-nf: #ebebeb;
    --fallback-su: #008159;
    --fallback-wa: #ffcf2d;
    --fallback-er: #ff5c5c;
    --fallback-bc: #b9beb4;
    --fallback-pc: #fdfdfd;
    --fallback-sc: #fdfdfd;
    --fallback-ac: #212427;
    --fallback-nc: #212427;
    --fallback-inc: #212427;
    --fallback-suc: #ff1a3f;
    --fallback-wac: #2b2b2b;
    --fallback-erc: #fdfdfd;
  }
  @media (prefers-color-scheme: dark) {
    :root {
      color-scheme: light;
      --fallback-p: #28818f;
      --fallback-s: #ac5656;
      --fallback-a: #ffe17d;
      --fallback-n: #fdfdfd;
      --fallback-b1: #f0f0f0;
      --fallback-b2: #b9beb4;
      --fallback-b3: #212427;
      --fallback-in: #a0e2e1;
      --fallback-pf: #7ad8c5;
      --fallback-sf: #ff501a;
      --fallback-af: #ff33ad;
      --fallback-nf: #ebebeb;
      --fallback-su: #008159;
      --fallback-wa: #ffcf2d;
      --fallback-er: #ff5c5c;
      --fallback-bc: #b9beb4;
      --fallback-pc: #fdfdfd;
      --fallback-sc: #fdfdfd;
      --fallback-ac: #212427;
      --fallback-nc: #212427;
      --fallback-inc: #212427;
      --fallback-suc: #ff1a3f;
      --fallback-wac: #2b2b2b;
      --fallback-erc: #fdfdfd;
    }
  }
}
body {
  color: rgb(var(--foreground-rgb));
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: sans-serif;
}

/* styles.css */

.skeleton-loader {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite linear;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
